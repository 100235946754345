import React from "react";
import { UiImage, UiLink } from "@dex/bubl-ui";
import Container from "../../elements/Container/Container";
import Space from "../../elements/Space/Space";

const HomeScreen: React.FC<HomeScreenProps> = (props) => {

    return (

        <>
            <Container>

                <UiImage
                    src={require('../../assets/images/logo.png')}
                    type={"local"}
                    style={{ maxWidth: "80%" }}
                />

                <Space height={12} />

                <UiLink
                    path="https://itunes.com"
                    target="_blank"
                >

                    <UiImage
                        src={require('../../assets/images/itunes.png')}
                        type={"local"}
                        style={{ maxWidth: "75%", display: "block", margin: "auto" }}
                    />

                </UiLink>

                <Space height={2} />

                <UiLink
                    path="https://google.com"
                    target="_blank"
                >

                    <UiImage
                        src={require('../../assets/images/playstore.png')}
                        type={"local"}
                        style={{ maxWidth: "75%", display: "block", margin: "auto" }}
                    />

                </UiLink>

            </Container>

        </>

    );
};

interface HomeScreenProps {
    [key: string]: any;
}

export default HomeScreen;
