import { useRouter } from "@dex/bubl-ui";
import { config } from "@dex/bubl-web";
import { hash } from "@dex/bubl-helpers";

import protectedHandler from "./Routes.protectedHandler";
import PageNotFoundScreen from "../screens/PageNotFoundScreen/PageNotFoundScreen";
import HomeScreen from "../screens/HomeScreen/HomeScreen";
import ResetPasswordScreen from "../screens/ResetPasswordScreen/ResetPasswordScreen";

export default () => {

    useRouter.protectedHandler = protectedHandler;

    if (config.env === "local") {

    }

    useRouter.addRoute({
        name: "404",
        component: PageNotFoundScreen,
        link: "/404",
        options: {},
    });

    useRouter.addRoute({
        name: "Home",
        component: HomeScreen,
        link: "/",
        options: {},
    });

    useRouter.addRoute({
        name: "ResetPassword",
        component: ResetPasswordScreen,
        link: "/reset-password/:key",
        options: {
            title: "Reset Password",
        },
    });

    return process.env.NODE_ENV === "development" ? hash(useRouter.routes) : "";


};
