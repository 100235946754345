import React, { useEffect, useState } from "react";
import { UiView, UiRow, UiCol, useStore, useLink, UiText, UiImage } from "@dex/bubl-ui";
import { get } from "@dex/bubl-helpers";

import styles from "./ResetPasswordScreen.module.scss";

import Space from "../../elements/Space/Space";
import ResetPasswordForm from "../../groups/ResetPasswordForm/ResetPasswordForm";
import Container from "../../elements/Container/Container";

const ResetPasswordScreen: React.FC<ResetPasswordScreenProps> = (props) => {

    const [user] = useStore("appUser");

    const resetKey = get(props, 'screenProps.routeParams.key');

    return (

        <Container>

            <UiImage
                src={require('../../assets/images/logo.png')}
                type={"local"}
                filters={{
                    width: 220
                }}
            />

            <Space height={8} />

            <UiText className={styles.title}>RESET YOUR PASSWORD</UiText>

            <Space height={7} />

            <ResetPasswordForm resetKey={resetKey} />

        </Container>

    );
};

interface ResetPasswordScreenProps {
    [key: string]: any;
}

export default ResetPasswordScreen;
