import React, { useMemo } from 'react';
import { useStore } from '@dex/bubl-ui';
import { config, AppWrapper } from '@dex/bubl-web';

import './styles/Web.scss';
import Routes from './routes/Routes';
import SplashLogo from './elements/SplashLogo/SplashLogo';

export default function App() {

    config.init({
        siteName: "Lila+",
    });

    const [key] = useStore("appKey", 1);

    const routeHash = Routes();

    return useMemo(() => {

        return (

            <>

                <AppWrapper
                    key={key + routeHash}
                    loadingScreen={() => {
                        return (
                            <SplashLogo />
                        )
                    }}
                />

            </>

        )

    }, [key, routeHash]);

}
