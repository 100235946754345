import React from 'react';
import { useStore } from '@dex/bubl-ui';
// import LoginScreen from '../screens/LoginScreen/LoginScreen';

interface ProtectedHandlerProps {
    [key: string]: any;
}

const ProtectedHandler: React.FC<ProtectedHandlerProps> = (props) => {

    const [user] = useStore("appUser");

    // console.log("Protect", user);

    // if (user.loggedIn) {

        return <props.screenProps.routeParams.component {...props} />

    // } else {
        // return null;
    // }

};

export default ProtectedHandler;