import React from 'react';
import { UiView } from '@dex/bubl-ui';

import styles from './Container.module.scss';

interface ContainerProps {
    [key: string]: any;
}

const Container: React.FC<ContainerProps> = (props) => {

    const {size} = props;

    const containerClasses = [styles.container];

    containerClasses.push(styles[`${size}Container`]);
    
    return (
        <UiView className={containerClasses}>
            {props.children}
        </UiView>
    )
}

export default Container;